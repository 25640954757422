/* Import Dependencies */
import { createRef } from "react";
import { Row, Col } from 'react-bootstrap';

/* Import Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";


const ScannedProducts = (props) => {
    const { scannedProducts, UpdateForm, RemoveFromForm } = props;

    /* Products ref handling */
    const productRefs = [];

    Object.keys(scannedProducts).forEach(() => {
        productRefs.push(createRef(null));
    });

    function AddAmount(barcode, index) {
        productRefs[index].current.value = (parseInt(productRefs[index].current.value)) + 1;

        UpdateForm('amount', parseInt(productRefs[index].current.value), barcode);
    }

    function SubtractAmount(barcode, index) {
        if (parseInt(productRefs[index].current.value) !== 0) {
            productRefs[index].current.value = (parseInt(productRefs[index].current.value)) - 1;

            UpdateForm('amount', parseInt(productRefs[index].current.value), barcode);
        }
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col className="px-3">
                        Gescande producten:
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(Object.keys(scannedProducts).length > 0) &&
                            Object.keys(scannedProducts).map((barcode, i) => {
                                const product = scannedProducts[barcode];

                                return (
                                    <Row key={i} className="stocktaking_m_scannedProductRow pt-3 pb-3 px-2">
                                        <Col>
                                            <Row>
                                                <Col xs={{ span: 12 }}>
                                                    <Row>
                                                        <Col className="negativeBooking_barcode">
                                                            {barcode}
                                                        </Col>
                                                        <Col className="negativeBooking_remove col-auto"
                                                            onClick={() => RemoveFromForm(barcode)}
                                                        >
                                                            <FontAwesomeIcon icon={faX} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="negativeBooking_productName">
                                                            {product['product_name']}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col xs={{ span: 3 }}>
                                                    <button type="button" className="negativeBooking_button w-100"
                                                        onClick={() => SubtractAmount(barcode, i)}
                                                    >
                                                        -
                                                    </button>
                                                </Col>
                                                <Col xs={{ span: 6 }}>
                                                    <input type="number"
                                                        defaultValue={0}
                                                        className="stocktaking_m_scannedProductRowAmount h-100 w-100"
                                                        ref={productRefs[i]}
                                                        onChange={(option) => props.UpdateForm('amount', option.target.value, barcode)}
                                                    />
                                                </Col>
                                                <Col xs={{ span: 3 }}>
                                                    <button type="button" className="negativeBooking_button w-100"
                                                        onClick={() => AddAmount(barcode, i)}
                                                    >
                                                        +
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ScannedProducts;