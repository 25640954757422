import axios from 'axios';


function OrderAddProduct(orderId, barcode, callback) {
    if (orderId && barcode) {
        const endPoint = "orderAddProduct";

        axios({
            method: "post",
            url: endPoint,
            data: {
                order_id: orderId,
                barcode: barcode
            },
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(result) {
            callback(result['data']);
        }).catch(error => {
            /* To be replaced by logger */
            console.warn(error);
        });
    }
}

export default OrderAddProduct;