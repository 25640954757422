/* Import Dependencies */
import { Row, Col } from 'react-bootstrap';


const ProductDetails = (props) => {
    const { product } = props;

    return (
        <Row>
            <Col className="scan_m_productDetails py-2">
                <Row>
                    <Col className="fw-bold">
                        Product Details
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <p className="scan_m_productDetailsTitle"> Naam: </p>
                        <span className="scan_m_productDetailsValue"> {product['product_name']} </span>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <p className="scan_m_productDetailsTitle"> Leverancier: </p>
                        <span className="scan_m_productDetailsValue"> {product['supplier']['supplier_name']} </span>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <p className="scan_m_productDetailsTitle"> Beschrijving: </p>
                        <span className="scan_m_productDetailsValue"> {product['product_description']} </span>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ProductDetails;