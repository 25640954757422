import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

/* Import API */
import GetWarehouses from 'api/warehouses/GetWarehouses';


const StocktakingOptions = (props) => {
    const form = props.form;
    const fromWarehouse = props.fromWarehouse;

    const [warehouses, setWarehouses] = useState();

    useEffect(() => {
        GetWarehouses(Process);

        function Process(warehouses) {
            setWarehouses(warehouses);
        }
    }, []);

    if (warehouses) {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col md={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col md={{ span: 6 }} className="stocktaking_optionTab">
                                    Options
                                </Col>
                                <Col md={{ span: 6 }} className="stocktaking_optionTab">
                                    Saved
                                </Col>
                            </Row>

                            <Row>
                                <Col className="stocktaking_optionsBody bg-white">
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <input type="checkbox"
                                                        onChange={(toggle) => props.ToggleFromWarehouse(!toggle.target.checked)}
                                                    />

                                                    Van magazijn:

                                                    <select onChange={(option) => props.UpdateForm('from_warehouse', option.target.value)}
                                                        value={form['from_warehouse']}
                                                        disabled={fromWarehouse}
                                                    >
                                                        <option value="">
                                                            Kies magazijn
                                                        </option>

                                                        {Object.keys(warehouses).map((index, _i) => {
                                                            const warehouse = warehouses[index];

                                                            return (
                                                                <option key={index}
                                                                    value={warehouse['id']}
                                                                >
                                                                    {warehouse['warehouse_name']}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    Naar magazijn:

                                                    <select onChange={(option) => props.UpdateForm('to_warehouse', option.target.value)}
                                                        value={form['to_warehouse']}
                                                    >
                                                        <option value="">
                                                            Kies magazijn
                                                        </option>

                                                        {Object.keys(warehouses).map((index, _i) => {
                                                            const warehouse = warehouses[index];

                                                            return (
                                                                <option key={index}
                                                                    value={warehouse['id']}
                                                                >
                                                                    {warehouse['warehouse_name']}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <button onClick={() => props.SubmitForm()}>
                                                Uitvoeren
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default StocktakingOptions;