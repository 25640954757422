import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";


/* Import Components */
import Main from './templates/main/Main';
import Landing from 'templates/main/Landing';
import Kaprun from 'templates/Kaprun';


const App = () => {
  const auth0 = useAuth0();

  if (!auth0.isLoading) {
    if (auth0.isAuthenticated) {
      return (
        <Router>
          <Routes>
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      );
    } else {
      return (
        <Router>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/kaprun' element={<Kaprun />} />
          </Routes>
        </Router>
      );
    }
  }
}

export default App;
