/* Import Dependencies */
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';


const OrderLists = (props) => {
    const { orders, chosenOrder, OpenOrder, ToggleModal } = props;

    return (
        <Row className="h-100">
            <Col className="h-100">
                <Row className="order_m_orderListsTitle">
                    <Col>
                        <p> Bestellijsten </p>
                    </Col>
                    <Col className="col-auto">
                        <button type="button" className="order_m_orderListAddButton text-white px-3 py-1"
                            onClick={() => ToggleModal()}
                        >
                            Nieuw
                        </button>
                    </Col>
                </Row>
                <Row className="order_m_orderListsContent">
                    <Col className="order_m_orderListsBody bg-white">
                        {Object.keys(orders).length > 0 ?
                            <>
                                {Object.keys(orders).map((key, i) => {
                                    const order = orders[key];

                                    /* Check if order is chosen */
                                    let active;

                                    if (order['id'] === chosenOrder['id']) {
                                        active = 'active';
                                    }

                                    return (
                                        <Row key={i}
                                            onClick={() => OpenOrder(order['id'])}
                                        >
                                            <Col className={`order_m_ordersListRow ${active} w-100 py-2`}>
                                                {order['order_name']}
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </>
                            : <p> Nog geen bestellijsten </p>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default OrderLists;