/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import ScannedProducts from './components/ScannedProducts';
import StocktakingOptions from './components/StocktakingOptions';


const StocktakingDefault = (props) => {
    const {
        form, bookingReasons, fromWarehouse,
        UpdateForm, SubmitForm, ToggleFromWarehouse
    } = props;

    return (
        <Container fluid className="contentWithHeader pt-4">
            <Row>
                <Col md={{ span: 9 }}>
                    <ScannedProducts scannedProducts={form['products']}
                        bookingReasons={bookingReasons}
                        fromWarehouse={fromWarehouse}

                        UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                    />
                </Col>

                <Col md={{ span: 3 }}>
                    <StocktakingOptions form={form}
                        fromWarehouse={fromWarehouse}

                        UpdateForm={(field, value) => UpdateForm(field, value)}
                        SubmitForm={() => SubmitForm()}
                        ToggleFromWarehouse={(toggle) => ToggleFromWarehouse(toggle)}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default StocktakingDefault;