/* Import Dependencies */
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import AddOrderList from './mobile/AddOrderList';
import OrderLists from './mobile/OrderLists';
import OrderContent from './mobile/OrderContent';


const OrderMobile = (props) => {
    const {
        orders, chosenOrder, initialFormValues, suppliers,
        OpenOrder, AddOrder, PatchOrder, SetChosenOrder
    } = props;

    /* Modal Handling */
    const [modalToggle, setModalToggle] = useState(false);

    return (
        <Container fluid className="contentWithHeaderMobile pt-4 pb-4">
            <Row className="h-100">
                <Col className="h-100">
                    <AddOrderList suppliers={suppliers}
                        modalToggle={modalToggle}

                        AddOrder={(form) => AddOrder(form)}
                        SetModalToggle={() => setModalToggle(false)}
                    />

                    {Object.keys(chosenOrder).length === 0 ?
                        <OrderLists orders={orders}
                            chosenOrder={chosenOrder}

                            OpenOrder={(order_id) => OpenOrder(order_id)}
                            ToggleModal={() => setModalToggle(true)}
                        />
                        : <OrderContent order={chosenOrder}
                            initialFormValues={initialFormValues}

                            PatchOrder={(formData) => PatchOrder(formData)}
                            ReturnToOverview={() => SetChosenOrder({})}
                        />
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default OrderMobile;