/* Import Dependencies */
import { useRef } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX, faCamera } from '@fortawesome/free-solid-svg-icons';


const BarcodeFieldMobile = (props) => {
    const { scannedBarcode, mainMenuMobileToggle, SetMainMenuMobileToggle, SetBarcode, SubmitBarcode, SetCamera } = props;

    /* Conditional Class when mobile menu is toggled */
    const menuToggled = classNames({
        'opacity-0': mainMenuMobileToggle
    });

    /* Barcode field handling */
    const ref = useRef(null);

    function HandleKeyPress(event) {
        if (event.key === 'Enter') {
            /* Remove focus */
            ref.current.blur();

            /* Submit barcode */
            SubmitBarcode();
        }
    }

    return (
        <Row>
            <Col className="sectionBelow position-absolute bottom-0 py-2">
                <Row>
                    <Col xs={{ span: 2 }} className="d-flex justify-content-center align-items-center"
                        onClick={() => SetMainMenuMobileToggle()}
                    >
                        {!mainMenuMobileToggle ?
                            <FontAwesomeIcon icon={faBars} className="sectionBelowIcon" />
                            : <FontAwesomeIcon icon={faX} className="sectionBelowIcon" />
                        }
                    </Col>
                    <Col xs={{ span: 8 }} className={menuToggled}>
                        <input type="text" className="sectionBelowBarcodeField w-100"
                            autoComplete='off'
                            defaultValue={scannedBarcode}
                            ref={ref}

                            onChange={(input) => SetBarcode(input)}
                            onKeyPress={HandleKeyPress}
                        />
                    </Col>
                    <Col xs={{ span: 2 }} className={`${menuToggled} d-flex justify-content-center align-items-center`}
                        onClick={() => SetCamera()}
                    >
                        <FontAwesomeIcon icon={faCamera} className="sectionBelowIcon" />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default BarcodeFieldMobile;