/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import ProductDetails from './mobile/ProductDetails';
import ProductWarehouses from './mobile/ProductWarehouses';


/* Mobile Variant */
const ScanMobile = (props) => {
    const { product } = props;

    return (
        <Container fluid className="contentWithHeaderMobile pt-4">
            <Row>
                <Col>
                    <ProductDetails product={product} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <ProductWarehouses warehousesProducts={product['warehouses_products']} />
                </Col>
            </Row>
        </Container>
    );
}

export default ScanMobile;