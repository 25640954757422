/* Import Dependencies */
import axios from 'axios';


function PatchWarehousesProducts(formData, callback) {
    const endPoint = "warehousesProducts";

    axios({
        method: "patch",
        url: endPoint,
        data: formData,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(function (result) {
        callback(result['data']);
    }).catch(error => {
        /* To be replaced by logger */
        console.warn(error);
    });
}

export default PatchWarehousesProducts;