/* Import Dependencies */
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/* Import Styles */
import './stocktaking.css';

/* Import API */
import GetProduct from 'api/product/GetProduct';
import GetBookingReasons from 'api/bookingReasons/GetBookingReasons';
import PatchWarehousesProducts from 'api/warehousesProducts/PatchWarehousesProducts';

/* Import Components */
import Header from 'templates/header/Header';
import StocktakingDefault from './StocktakingDefault';
import StocktakingMobile from './StocktakingMobile';


const StocktakingSection = (props) => {
    const { barcode, mobile, ResetBarcode } = props;

    /* Hooks */
    const auth0 = useAuth0();

    /* Get booking reasons */
    const [bookingReasons, setBookingReasons] = useState();

    useEffect(() => {
        GetBookingReasons(Process);

        function Process(bookingReasons) {
            setBookingReasons(bookingReasons);
        }
    }, [])

    /* Process scanned products */
    useEffect(() => {
        if (barcode && !form['products'][barcode]) {
            GetProduct(barcode, Process);
        }

        function Process(product) {
            const scannedProduct = {
                product_id: product['id'],
                product_name: product['product_name'],
                amount: 0,
                booking_reason: null
            }

            PushToForm(barcode, scannedProduct);

            /* Reset stocktaking barcode */
            ResetBarcode();
        }
    }, [barcode]);

    /* Form handling */
    const [form, setForm] = useState({
        from_warehouse: '',
        to_warehouse: '',
        products: {},
        user_id: auth0.user.sub
    });

    function PushToForm(barcode, scannedProduct) {
        const copyForm = { ...form };

        copyForm['products'][barcode] = scannedProduct;

        setForm(copyForm);
    }

    function UpdateForm(field, value, barcode = null) {
        const copyForm = { ...form };

        if (barcode) {
            copyForm['products'][barcode][[field]] = value;
        } else {
            copyForm[[field]] = value;
        }

        setForm(copyForm);
    }

    function SubmitForm() {
        PatchWarehousesProducts(form, Process);

        function Process(result) {
            /* Reset form */
            setForm({
                from_warehouse: '',
                to_warehouse: '',
                products: {},
                user_id: auth0.user.sub
            });
        }
    }

    /* Handle from warehouse option */
    const [fromWarehouse, setFromWarehouse] = useState(true);

    function ToggleFromWarehouse(disabled) {
        if (disabled) {
            UpdateForm('from_warehouse', '');
        }

        setFromWarehouse(disabled);
    }

    /* Set header information */
    const headerInfo = {
        'title': 'Inventarisatie',
        'subTitle': ''
    }

    return (
        <>
            <Header info={headerInfo} />

            {/* Body */}
            {!mobile ?
                <StocktakingDefault form={form}
                    bookingReasons={bookingReasons}
                    fromWarehouse={fromWarehouse}

                    UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                    SubmitForm={() => SubmitForm()}
                    ToggleFromWarehouse={(toggle) => ToggleFromWarehouse(toggle)}
                />
                : <StocktakingMobile form={form}
                    bookingReasons={bookingReasons}
                    fromWarehouse={fromWarehouse}

                    UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                    SubmitForm={() => SubmitForm()}
                    ToggleFromWarehouse={(toggle) => ToggleFromWarehouse(toggle)}
                />
            }
        </>
    );
}

export default StocktakingSection;