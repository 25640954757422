/* Import Dependencies */
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Card } from 'react-bootstrap';

/* Import Styles */
import './landing.css';


const Landing = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Container fluid className="landing_body h-100">
            <Row className="h-100 pt-5">
                <Col md={{ span: 4, offset: 4 }} className="mt-5">
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Welkom bij Johto!
                            </Card.Title>
                            <Card.Text>
                                Logt u alstublieft in om gebruik te maken van de applicatie
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <button type="button" className="landing_loginButton px-3 py-1"
                                onClick={() => loginWithRedirect()}
                            >
                                Login
                            </button>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Landing;