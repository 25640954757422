import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

/* Import API */
import GetWarehouses from 'api/warehouses/GetWarehouses';


const StocktakingOptions = (props) => {
    const form = props.form;
    const fromWarehouse = props.fromWarehouse;

    const [warehouses, setWarehouses] = useState();

    useEffect(() => {
        GetWarehouses(Process);

        function Process(warehouses) {
            setWarehouses(warehouses);
        }
    }, []);

    if (warehouses) {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col className="py-2 px-3 bg-white">
                            <Row>
                                <Col>
                                    <input type="checkbox"
                                        onChange={(toggle) => props.ToggleFromWarehouse(!toggle.target.checked)}
                                    />

                                    Van magazijn:
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <select onChange={(option) => props.UpdateForm('from_warehouse', option.target.value)}
                                        value={form['from_warehouse']}
                                        disabled={fromWarehouse}
                                    >
                                        <option value="">
                                            Kies magazijn
                                        </option>

                                        {Object.keys(warehouses).map((index, _i) => {
                                            const warehouse = warehouses[index];

                                            return (
                                                <option key={index}
                                                    value={warehouse['id']}
                                                >
                                                    {warehouse['warehouse_name']}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Naar magazijn:
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <select onChange={(option) => props.UpdateForm('to_warehouse', option.target.value)}
                                        value={form['to_warehouse']}
                                    >
                                        <option value="">
                                            Kies magazijn
                                        </option>

                                        {Object.keys(warehouses).map((index, _i) => {
                                            const warehouse = warehouses[index];

                                            return (
                                                <option key={index}
                                                    value={warehouse['id']}
                                                >
                                                    {warehouse['warehouse_name']}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default StocktakingOptions;