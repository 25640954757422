/* Import Dependencies */
import { Row, Col } from 'react-bootstrap';


const ScannedProducts = (props) => {
    const scannedProducts = props.scannedProducts;
    const bookingReasons = props.bookingReasons;
    const fromWarehouse = props.fromWarehouse;

    return (
        <Row>
            <Col>
                <Row>
                    <Col className="px-3">
                        Gescande producten
                    </Col>
                </Row>
                <Row>
                    <Col className="stocktaking_m_scannedProductsContent">
                        {(Object.keys(scannedProducts).length > 0) &&
                            Object.keys(scannedProducts).map((barcode, i) => {
                                const product = scannedProducts[barcode];

                                return (
                                    <Row key={i} className="stocktaking_m_scannedProductRow pt-2 pb-3 px-2">
                                        <Col>
                                            <Row>
                                                <Col xs={{ span: 9 }}>
                                                    <Row>
                                                        <Col>
                                                            {product['product_name']}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <select onChange={(option) => props.UpdateForm('booking_reason', option.target.value, barcode)}
                                                                disabled={!fromWarehouse}
                                                            >
                                                                <option value=""> Kies boekingsreden </option>

                                                                {Object.keys(bookingReasons).map((i, key) => {
                                                                    const bookingReason = bookingReasons[i];

                                                                    return (
                                                                        <option key={key}
                                                                            value={bookingReason['id']}
                                                                        >
                                                                            {bookingReason['booking_reason_name']}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={{ span: 3 }}>
                                                    <input type="number"
                                                        defaultValue={0}
                                                        className="stocktaking_m_scannedProductRowAmount h-100 w-100"
                                                        onChange={(option) => props.UpdateForm('amount', option.target.value, barcode)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ScannedProducts;