/* Import Dependencies */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col } from 'react-bootstrap';

/* Import Components */
import BarcodeFieldMobile from './BarcodeFieldMobile';


const MainMenuMobile = (props) => {
    const { scannedBarcode, routePermissions, ProcessBarcode, SetCamera } = props;

    /* Hooks */
    const auth0 = useAuth0();

    /* Main Menu Mobile toggle */
    const [mainMenuMobileToggle, setMainMenuMobileToggle] = useState(false);

    /* Main Menu Mobile conditional classes */
    const mainMenuMobileClasses = classNames({
        'mainMenuMobile': true,
        'active': mainMenuMobileToggle
    });

    /* Handling barcode input */
    const [barcode, setBarcode] = useState({});

    function SetBarcode(input) {
        const barcodeValue = input.target.value;

        setBarcode(barcodeValue);
    }

    function SubmitBarcode() {
        if (barcode) {
            ProcessBarcode(barcode);
        }
    }

    if (Object.keys(routePermissions).length > 0) {
        return (
            <Row>
                <Col className={`${mainMenuMobileClasses} px-5 pt-5`}>
                    <Row>
                        {/* Left Row */}
                        <Col>
                            {routePermissions['permissions'].includes('scan') &&
                                <Link to="/scan" onClick={() => setMainMenuMobileToggle(!mainMenuMobileToggle)}>
                                    <Row>
                                        <Col xs={{ span: 10, offset: 1 }} className="mainMenuMobileItem d-flex align-items-center justify-content-center">
                                            <p> Scannen </p>
                                        </Col>
                                    </Row>
                                </Link>
                            }
                            {routePermissions['permissions'].includes('stocktaking') &&
                                <Link to="/stocktaking" onClick={() => setMainMenuMobileToggle(!mainMenuMobileToggle)}>
                                    <Row className="pt-3">
                                        <Col xs={{ span: 10, offset: 1 }} className="mainMenuMobileItem d-flex align-items-center justify-content-center">
                                            <p> Inventarisatie </p>
                                        </Col>
                                    </Row>
                                </Link>
                            }

                            {/* Log-out button */}
                            <Row className="pt-3">
                                <Col xs={{ span: 10, offset: 1 }} className="mainMenuMobileItem d-flex align-items-center justify-content-center"
                                    onClick={() => auth0.logout()}
                                >
                                    <p> Log-out </p>
                                </Col>
                            </Row>
                        </Col>
                        {/* Right Row */}
                        <Col>
                            {routePermissions['permissions'].includes('negativeBooking') &&
                                <Link to="/negativeBooking" onClick={() => setMainMenuMobileToggle(!mainMenuMobileToggle)}>
                                    <Row>
                                        <Col xs={{ span: 10, offset: 1 }} className="mainMenuMobileItem d-flex align-items-center justify-content-center">
                                            <p> Afboeken </p>
                                        </Col>
                                    </Row>
                                </Link>
                            }
                            {routePermissions['permissions'].includes('order') &&
                                <Link to="/order" onClick={() => setMainMenuMobileToggle(!mainMenuMobileToggle)}>
                                    <Row className="pt-3">
                                        <Col xs={{ span: 10, offset: 1 }} className="mainMenuMobileItem d-flex align-items-center justify-content-center">
                                            <p> Bestellen </p>
                                        </Col>
                                    </Row>
                                </Link>
                            }
                        </Col>
                    </Row>
                </Col>

                <BarcodeFieldMobile scannedBarcode={scannedBarcode}
                    mainMenuMobileToggle={mainMenuMobileToggle}

                    SetMainMenuMobileToggle={() => setMainMenuMobileToggle(!mainMenuMobileToggle)}
                    SetBarcode={(input) => SetBarcode(input)}
                    SubmitBarcode={() => SubmitBarcode()}
                    SetCamera={() => SetCamera()}
                />
            </Row>
        );
    }
}

export default MainMenuMobile