/* Import Dependencies */
import { useEffect, useState } from 'react';
import './scan.css';

/* Import Components */
import Header from 'templates/header/Header';
import ScanDefault from './ScanDefault';
import ScanMobile from './ScanMobile';
import DefaultPage from './components/DefaultPage';

/* Import API */
import GetProduct from 'api/product/GetProduct';


const ScanSection = (props) => {
    const { scannedBarcode, mobile } = props;

    const [product, setProduct] = useState();
    const [barcode, setBarcode] = useState('');

    useEffect(() => {
        if (scannedBarcode && scannedBarcode != barcode) {
            GetProduct(scannedBarcode, Process);

            function Process(product) {
                setProduct(product);
                setBarcode(product['product_barcode']);
            }
        }
    }, [scannedBarcode]);

    const headerInfo = {
        'title': product ?
            !mobile ? product['product_barcode']
                : `${product['product_barcode']} - ${product['product_name']}`
            : 'Barcode'
        ,
        'subTitle': product && !mobile && product['product_name']
    }

    if (product) {
        return (
            <>
                <Header info={headerInfo} />

                {/* Body */}
                {!mobile ?
                    <ScanDefault product={product} />
                    : <ScanMobile product={product} />
                }
            </>
        );
    } else {
        return (
            <DefaultPage />
        );
    }
}

export default ScanSection;