/* Import Dependencies */
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { Auth0Provider } from "@auth0/auth0-react";

/* Import Styles */
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

/* Import components */
import App from './App';


axios.defaults.baseURL = "https://johto.nl/api";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain="johto.eu.auth0.com"
    clientId="O3pJRG7iwb0MOTWMyOGOQTUSU4BSXVf9"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://johto.eu.auth0.com/api/v2/",
    }}
  >
    <App />
  </Auth0Provider>
);
