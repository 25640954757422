import React, { useEffect, useState } from "react";
import Quagga from "quagga";
import classNames from "classnames";


const Scanner = (props) => {
    const { camera, onDetected } = props;

    const [quaggaInitialized, setQuaggaInitialized] = useState(false);

    const detected = (result) => {
        console.log(result);

        onDetected(result.codeResult.code);
    };

    const quagga = Quagga;

    function startQuagga() {
        quagga.init({
            inputStream: {
                type: "LiveStream",
                target: document.querySelector('#interactive'),
                constraints: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    facingMode: "environment"
                }
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: 2,
            frequency: 10,
            decoder: {
                readers: ["ean_reader", "code_128_reader"]
            },
            locate: true
        }, err => {
            if (err) {
                console.log(err, "error msg");
            }
        });

        //detecting boxes on stream
        quagga.onProcessed(result => {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        Number(drawingCanvas.getAttribute("width")),
                        Number(drawingCanvas.getAttribute("height"))
                    );
                    result.boxes
                        .filter(function (box) {
                            return box !== result.box;
                        })
                        .forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                color: "green",
                                lineWidth: 2
                            });
                        });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: "#00F",
                        lineWidth: 2
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: "x", y: "y" },
                        drawingCtx,
                        { color: "red", lineWidth: 3 }
                    );
                }
            }
        });

        quagga.onDetected(detected);

        setQuaggaInitialized(true);
    }

    function stopQuagga() {
        quagga.stop();
    }

    if (camera && quaggaInitialized === false) {
        startQuagga();
    } else if (quaggaInitialized && !camera) {
        setQuaggaInitialized(false);

        stopQuagga();
    }

    const cameraActive = classNames({
        'd-none': !camera
    });

    return (
        <>
            <div id="interactive" className={`viewport ${cameraActive}`} />
        </>
    );
};

export default Scanner;
