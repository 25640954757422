import { Row, Col } from 'react-bootstrap';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';


const ScannedProducts = (props) => {
    const scannedProducts = props.scannedProducts;
    const bookingReasons = props.bookingReasons;
    const fromWarehouse = props.fromWarehouse;

    return (
        <Row>
            <Col md={{ span: 12 }}>
                <Row>
                    <Col className="stocktaking_scannedProductsTitleBar">
                        <Row>
                            <Col md={{ span: 11 }}>
                                Gescande producten
                            </Col>
                            <Col md={{ span: 1 }} className="stocktaking_scannedProductsSaveIcon">
                                <FontAwesomeIcon icon={faSave} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className="stocktaking_scannedProductsSpecBar">
                        <Row>
                            <Col md={{ span: 6 }}>
                                Naam van het product
                            </Col>
                            <Col md={{ span: 3 }}>
                                Boekingreden
                            </Col>
                            <Col md={{ span: 3 }}>
                                Aantal
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className="stocktaking_scannedProductsContent">
                        {(Object.keys(scannedProducts).length > 0) &&
                            Object.keys(scannedProducts).map((barcode, i) => {
                                const product = scannedProducts[barcode];

                                return (
                                    <Row key={i} className="stocktaking_scannedProductRow">
                                        <Col md={{ span: 6 }}>
                                            {product['product_name']}
                                        </Col>
                                        <Col md={{ span: 3 }}>
                                            <select onChange={(option) => props.UpdateForm('booking_reason', option.target.value, barcode)}
                                                disabled={!fromWarehouse}
                                            >
                                                <option value=""> Kies boekingsreden </option>

                                                {Object.keys(bookingReasons).map((i, key) => {
                                                    const bookingReason = bookingReasons[i];

                                                    return (
                                                        <option key={key}
                                                            value={bookingReason['id']}
                                                        >
                                                            {bookingReason['booking_reason_name']}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={{ span: 3 }}>
                                            <input type="number"
                                                defaultValue={0}
                                                className="stocktaking_scannedProductRowAmount h-75 w-50"
                                                onChange={(option) => props.UpdateForm('amount', option.target.value, barcode)}
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default ScannedProducts;