import { Row, Col } from 'react-bootstrap';

/* Import Components */
import AddOrderList from './AddOrderList';


const OrderLists = (props) => {
    const { suppliers, orders, chosenOrder, AddOrder, OpenOrder } = props;

    return (
        <Row>
            <Col className="overflow-hidden">
                <Row>
                    <Col className="order_orderListsHeader d-flex align-items-center">
                        Bestellijsten
                    </Col>
                </Row>
                {/* Orderlist rows */}
                <Row>
                    <Col className="order_orderListsBody bg-white">
                        {Object.keys(orders).map((key, i) => {
                            const order = orders[key];

                            /* Check if order is chosen */
                            let active;

                            if (order['id'] === chosenOrder['id']) {
                                active = 'active';
                            }

                            return (
                                <Row key={i}
                                    onClick={() => OpenOrder(order['id'])}
                                >
                                    <Col className={`order_ordersListRow ${active} w-100 py-2`}>
                                        {order['order_name']}
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                </Row>
                {/* Option for adding an order list */}
                <Row>
                    <Col>
                        <AddOrderList suppliers={suppliers} 
                            AddOrder={(formData) => AddOrder(formData)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default OrderLists;