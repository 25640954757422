import { Row, Col } from "react-bootstrap";

/* Import media */
import scanPlaceholder from 'webroot/img/scanPlaceholder.png';


const DefaultPage = () => {
    return (
        <Row className="h-100">
            <Col md={{ span: 12 }} className="my-auto">
                <Row>
                    <Col md={{ span: 4, offset: 4 }} className="scan_placeholderText">
                        <p> Scan een product </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 4, offset: 4 }}>
                        <img src={scanPlaceholder} alt="Scan placeholder image" className="img-fluid" />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default DefaultPage;