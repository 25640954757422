/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import OrderLists from './components/OrderLists'
import OrderContent from './components/OrderContent';


const OrderDefault = (props) => {
    const {
        orders, chosenOrder, initialFormValues, suppliers,
        OpenOrder, AddOrder, PatchOrder, FinishOrder
    } = props;

    return (
        <Container fluid className="contentWithHeader pt-4">
            <Row>
                <Col md={{ span: 3 }}>
                    <OrderLists suppliers={suppliers}
                        orders={orders}
                        chosenOrder={chosenOrder}

                        AddOrder={(formData) => AddOrder(formData)}
                        OpenOrder={(order_id) => OpenOrder(order_id)}
                    />
                </Col>
                <Col md={{ span: 9 }} className="ps-5">
                    <OrderContent order={chosenOrder}
                        initialFormValues={initialFormValues}

                        PatchOrder={(formData) => PatchOrder(formData)}
                        FinishOrder={(order) => FinishOrder(order)}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default OrderDefault;