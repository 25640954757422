import { Row, Col } from 'react-bootstrap';


const ProductDetails = (props) => {
    const product = props.product;

    return (
        <Row>
            <Col md={{ span: 5 }} className="scan_productDetails p-3">
                <Row>
                    <Col md={{ span: 12 }} className="scan_productDetailsTitle">
                        Product details
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 12 }} className="scan_productDetailsRow pt-2 pb-1">
                        <span className="scan_productDetailsRowTitle"> Naam: </span> {product['product_name']}
                    </Col>
                    <Col md={{ span: 12 }} className="scan_productDetailsRow pt-2 pb-1">
                        <span className="scan_productDetailsRowTitle"> Leverancier: </span> {product['supplier']['supplier_name']}
                    </Col>
                    <Col className="scan_productDetailsRow last pt-2 pb-1">
                        <span className="scan_productDetailsRowTitle"> Beschrijving: </span> {product['product_description']}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ProductDetails;