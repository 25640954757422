/* Import Dependencies */
import { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

/* Import Styles */
import './negativeBooking.css';

/* Import Components */
import Header from 'templates/header/Header';
import NegativeBookingMobile from './NegativeBookingMobile';

/* Import API */
import GetProduct from 'api/product/GetProduct';
import PatchWarehousesProducts from 'api/warehousesProducts/PatchWarehousesProducts';


const NegativeBookingSection = (props) => {
    const { barcode, mobile, ResetBarcode } = props;

    /* Hooks */
    const auth0 = useAuth0();

    /* Process scanned products */
    useEffect(() => {
        if (barcode && !form['products'][barcode]) {
            GetProduct(barcode, Process);
        }

        function Process(product) {
            if (product) {
                const scannedProduct = {
                    product_id: product['id'],
                    product_name: product['product_name'],
                    amount: 0
                }

                PushToForm(barcode, scannedProduct);
            }

            /* Reset negative booking barcode */
            ResetBarcode();
        }
    }, [barcode]);

    /* HARDCODED Form handling HARDCODED */
    const [form, setForm] = useState({
        to_warehouse: 1,
        products: {},
        user_id: auth0.user.sub
    });

    function PushToForm(barcode, scannedProduct) {
        /* HARDCODED booking_reason id */
        scannedProduct['booking_reason'] = 5;

        const copyForm = { ...form };

        copyForm['products'][barcode] = scannedProduct;

        setForm(copyForm);
    }

    function RemoveFromForm(barcode) {
        const copyForm = { ...form };

        delete copyForm['products'][barcode];

        setForm(copyForm);
    }

    function UpdateForm(field, value, barcode = null) {
        const copyForm = { ...form };

        if (barcode) {
            copyForm['products'][barcode][[field]] = value;
        } else {
            copyForm[[field]] = value;
        }

        setForm(copyForm);
    }

    function SubmitForm() {
        PatchWarehousesProducts(form, Process);

        function Process() {
            /* HARDCODED Reset form HARDCODED */
            setForm({
                to_warehouse: 1,
                products: {},
                user_id: auth0.user.sub
            });
        }
    }

    const headerInfo = {
        'title': 'Afboeken'
    }

    return (
        <>
            <Header info={headerInfo} />

            {/* Body */}
            {!mobile ?
                <> </>
                : <NegativeBookingMobile form={form}
                    UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                    SubmitForm={() => SubmitForm()}
                    RemoveFromForm={(barcode) => RemoveFromForm(barcode)}
                />
            }
        </>
    );
}

export default NegativeBookingSection;