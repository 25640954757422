import { useEffect, useState } from 'react';
import './order.css';

/* Import Components */
import OrderDefault from './OrderDefault';
import OrderMobile from './OrderMobile';
import Header from 'templates/header/Header';

/* Import API */
import GetOrder from 'api/orders/GetOrder';
import GetOrders from 'api/orders/GetOrders';
import InsertOrder from 'api/orders/InsertOrder';
import UpdateOrder from 'api/orders/UpdateOrder';
import OrderAddProduct from 'api/orders/OrderAddProduct';
import CompleteOrder from 'api/orders/CompleteOrder';

import GetSuppliers from 'api/suppliers/GetSuppliers';


const OrderSection = (props) => {
    const { barcode, mobile, ResetBarcode } = props;

    const [orders, setOrders] = useState({});
    const [chosenOrder, setChosenOrder] = useState({});
    const [initialFormValues, setInitialFormValues] = useState();

    /* Update Order products list */
    useEffect(() => {
        if (Object.keys(chosenOrder).length > 0) {
            /* Check if product exists, if so, scroll to */
            if (Object.keys(chosenOrder['order_products']).length > 0) {
                if (barcode in chosenOrder['order_products']['products']) {
                    /* Scroll to */
                } else {
                    OrderAddProduct(chosenOrder['id'], barcode, Process);
                }
            } else {
                OrderAddProduct(chosenOrder['id'], barcode, (product) => Process(product, true));
            }
        }

        function Process(product, empty = false) {
            /* Update Order record if belongs to Supplier */
            if (product) {
                const copyOrders = { ...orders };

                if (empty) {
                    copyOrders[chosenOrder['id']]['order_products']['products'] = { barcode: product };
                } else {
                    copyOrders[chosenOrder['id']]['order_products']['products'][barcode] = product;
                }

                setOrders(copyOrders);

                /* Update chosen Order */
                const copyChosenOrder = { ...chosenOrder };

                copyChosenOrder['order_products']['products'][product['product_barcode']] = product;

                setChosenOrder(copyChosenOrder);

                /* Update initial form values */
                SetInitialFormValues(copyChosenOrder);
            } else {
                /* Give message about product not belonging to Supplier */
            }
        }

        /* Reset order barcode */
        ResetBarcode();
    }, [barcode]);

    /* Get Orders and Suppliers lists */
    const [suppliers, setSuppliers] = useState({});

    useEffect(() => {
        GetOrders('draft', ProcessOrders);

        function ProcessOrders(orders) {
            setOrders(orders);
        }

        GetSuppliers(ProcessSuppliers);

        function ProcessSuppliers(suppliers) {
            setSuppliers(suppliers);
        }
    }, []);

    /* Function for setting initial form values */
    function SetInitialFormValues(order) {
        const initialFormValues = {
            barcode: [],
            amount: []
        };

        for (const barcode in order['order_products']['products']) {
            initialFormValues['barcode'].push(barcode);

            if (order['order_products']['products'][barcode]['amount']) {
                initialFormValues['amount'].push(order['order_products']['products'][barcode]['amount']);
            } else {
                initialFormValues['amount'].push(0);
            }
        }

        setInitialFormValues(initialFormValues);
    }

    /* Function for adding Order to database */
    function AddOrder(formData) {
        const order = {
            order_name: formData['order_name'],
            supplier_id: formData['supplier_id'],
            auto_fill: formData['auto_fill']
        }

        InsertOrder(order, Process);

        function Process(order) {
            const copyOrders = { ...orders };

            copyOrders[order['id']] = order;

            setOrders(copyOrders);

            OpenOrder(order['id']);
        }
    }

    /* Function for updating an Order */
    function PatchOrder(formData) {
        const order = structuredClone(chosenOrder);

        order['order_products']['products'] = {};

        for (let i in formData['barcode']) {
            order['order_products']['products'][formData['barcode'][i]] = { amount: formData['amount'][i] };
        }

        UpdateOrder(order, Process);

        function Process(order) {
            const copyOrders = { ...orders };

            copyOrders[order['id']] = order;

            setOrders(copyOrders);

            setChosenOrder(order);

            SetInitialFormValues(order);
        }
    }

    /* Function for opening Order */
    function OpenOrder(order_id) {
        if (order_id) {
            GetOrder(order_id, Process);
        }

        function Process(order) {
            /* Set initial form values */
            SetInitialFormValues(order);

            setChosenOrder(order);
        }
    }

    /* Function for completing an Order */
    function FinishOrder(formData) {
        const orderProducts = {};

        for (let i in formData['barcode']) {
            orderProducts[formData['barcode'][i]] = { amount: formData['amount'][i] };
        }

        CompleteOrder(chosenOrder['id'], orderProducts, Process);

        function Process(order_id) {
            if (order_id) {
                /* Remove Order from Orders list */
                const copyOrders = { ...orders };

                delete copyOrders[order_id];

                setOrders(copyOrders);
                setChosenOrder({});
            }
        }
    }

    /* Set header information */
    const headerInfo = {
        'title': 'Bestellen',
        'subTitle': ''
    }

    return (
        <>
            <Header info={headerInfo} />

            {/* Body */}
            {!mobile ?
                <OrderDefault orders={orders}
                    chosenOrder={chosenOrder}
                    initialFormValues={initialFormValues}
                    suppliers={suppliers}

                    OpenOrder={(order_id) => OpenOrder(order_id)}
                    AddOrder={(formData) => AddOrder(formData)}
                    PatchOrder={(formData) => PatchOrder(formData)}
                    FinishOrder={(order) => FinishOrder(order)}
                />
                : <OrderMobile orders={orders}
                    chosenOrder={chosenOrder}
                    initialFormValues={initialFormValues}
                    suppliers={suppliers}

                    OpenOrder={(order_id) => OpenOrder(order_id)}
                    AddOrder={(formData) => AddOrder(formData)}
                    PatchOrder={(formData) => PatchOrder(formData)}
                    SetChosenOrder={(order) => setChosenOrder(order)}
                />
            }
        </>
    );
}

export default OrderSection;