/* Import Dependencies */
import { Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';


const OrderProducts = (props) => {
    const { orderProducts } = props;

    return (
        <Row>
            <Col className="bg-white">
                <FieldArray name="amount">
                    <Row>
                        <Col>
                            {Object.keys(orderProducts['products']).map((barcode, i) => {
                                const orderProduct = orderProducts['products'][barcode];

                                return (
                                    <Row key={i} className="order_m_contentProductRow">
                                        <Col>
                                            <Row className="mt-1">
                                                <Col className="order_m_contentProductName">
                                                    {orderProduct['product_name']}
                                                </Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col className="order_m_contentProductSub">
                                                    Voorraad: {orderProduct['warehouseproduct_stock']}
                                                </Col>
                                                <Col className="order_m_contentProductSub">
                                                    Min: {orderProduct['warehouseproduct_minimum_stock']}
                                                </Col>
                                                <Col className="order_m_contentProductSub">
                                                    Max: {orderProduct['warehouseproduct_maximum_stock']}
                                                </Col>
                                            </Row>
                                            <Row className="mt-1 mb-3">
                                                <Col>
                                                    <Field name={`amount.${i}`} type="number" className="w-100" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </Col>
                    </Row>
                </FieldArray>
            </Col>
        </Row>
    );
}

export default OrderProducts;