/* Import Dependencies */
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import BarcodeReader from 'react-barcode-reader'
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col } from 'react-bootstrap';

/* Import Styles */
import './main.css';

/* Components */
import MainMenu from './mainMenu/MainMenu';
import MainMenuMobile from './mainMenu/MainMenuMobile';
import Scanner from './components/Scanner';
import ScanSection from '../scanSection/ScanSection';
import ProductsSection from '../productsSection/ProductsSection';
import StocktakingSection from '../stocktakingSection/StocktakingSection';
import OrderSection from '../orderSection/OrderSection';

import NegativeBookingSection from 'templates/negativeBookingSection/NegativeBookingSection';

/* Import API */
import GetUser from 'api/users/GetUser';


const Main = () => {
    const auth0 = useAuth0();
    const location = useLocation();

    /* Screen width based functionality */
    const [mobile, setMobile] = useState(false);

    const updateWindowDimensions = () => {
        if (window.innerWidth > 500) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useEffect(() => {
        updateWindowDimensions();

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    /* User permissions */
    const [routePermissions, setRoutePermissions] = useState([]);

    useEffect(() => {
        GetUser(auth0.user.sub, Process);

        function Process(user) {
            setRoutePermissions(user['route_permissions']);
        }
    }, []);

    /* Barcode handling */
    const [barcode, setBarcode] = useState({});
    const [scannedBarcode, setScannedBarcode] = useState('');

    function ProcessBarcode(inputBarcode) {
        setScannedBarcode(inputBarcode);

        setTimeout(() => {
            const currentBarcode = { ...barcode };
            currentBarcode[location.pathname.replace('/', '')] = inputBarcode;

            setBarcode(currentBarcode);
        }, 300);
    }

    function ResetBarcode(location) {
        const copyBarcode = { ...barcode };

        delete copyBarcode[location];

        setBarcode(copyBarcode);
    }

    /* Camera Scanner */
    const [camera, setCamera] = useState(false);

    const onDetected = (cameraResult) => {
        setCamera(false);
        setScannedBarcode(cameraResult);
    };

    return (
        <Container fluid className='h-100'>
            <BarcodeReader
                // onError={}
                onScan={(barcode) => ProcessBarcode(barcode)}
            />

            <Row className='h-100'>
                <Col md={{ span: 2 }}>
                    {/* Main Menu */}
                    {!mobile ?
                        <MainMenu scannedBarcode={scannedBarcode}
                            ProcessBarcode={(barcode) => ProcessBarcode(barcode)}
                            SetCamera={() => setCamera(!camera)}
                        />
                        : <MainMenuMobile scannedBarcode={scannedBarcode}
                            routePermissions={routePermissions}

                            ProcessBarcode={(barcode) => ProcessBarcode(barcode)}
                            SetCamera={() => setCamera(!camera)}
                        />
                    }
                </Col>

                {/* Content section */}
                <Col md={{ span: 10 }} xs={{ span: 12 }} className="contentBody">
                    {/* Camera Scanner */}
                    <Scanner camera={camera} onDetected={onDetected} />

                    <Routes>
                        <Route path='/scan' element={<ScanSection scannedBarcode={barcode['scan']} mobile={mobile} />} />
                        <Route path='/products' element={<ProductsSection />} />
                        <Route path='/stocktaking' element={<StocktakingSection barcode={barcode['stocktaking']} mobile={mobile}
                            ResetBarcode={() => ResetBarcode('stocktaking')}
                        />} />
                        <Route path='/order' element={<OrderSection barcode={barcode['order']} mobile={mobile}
                            ResetBarcode={() => ResetBarcode('order')}
                        />} />

                        <Route path='/negativeBooking' element={<NegativeBookingSection barcode={barcode['negativeBooking']} mobile={mobile}
                            ResetBarcode={() => ResetBarcode('negativeBooking')}
                        />} />
                    </Routes>
                </Col>
            </Row>
        </Container>
    );
}

export default Main