import { Row, Col } from 'react-bootstrap';


const ProductWarehouses = (props) => {
    const warehousesProducts = props.warehousesProducts;

    return (
        <Row>
            <Col md={{ span: 8 }} className="scan_productWarehouses p-3">
                <Row>
                    <Col className='scan_productWarehousesTitle'>
                        Magazijnvoorraad
                    </Col>
                </Row>

                <Row className="p-2">
                    {warehousesProducts.map((warehouseProduct, i) => {
                        return (
                            <Col key={i} md={{ span: 3 }} className="scan_productWarehousesBlock">
                                <Row>
                                    <Col className="scan_productWarehousesBlockTitle">
                                        {warehouseProduct['warehouse']['warehouse_name']}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="scan_productWarehousesBlockContent">
                                        {`Voorraad: ${warehouseProduct['warehouseproduct_stock']}`}
                                    </Col>
                                </Row>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        </Row>
    );
}

export default ProductWarehouses;