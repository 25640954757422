/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import ProductDetails from './components/ProductDetails'
import ProductWarehouses from './components/ProductWarehouses';


const ScanDefault = (props) => {
    const { product } = props;

    return (
        <Container fluid className="contentWithHeader pt-4">
            <Row>
                <Col>
                    <ProductDetails product={product} />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <ProductWarehouses warehousesProducts={product['warehouses_products']} />
                </Col>
            </Row>
        </Container>
    );
}

export default ScanDefault;