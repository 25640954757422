/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Components */
import ScannedProducts from './mobile/ScannedProducts';


/* Mobile Variant */
const NegativeBookingMobile = (props) => {
    const { form, UpdateForm, SubmitForm, RemoveFromForm } = props;

    return (
        <Container fluid className="contentWithHeaderMobile pt-4">
            <Row className="negativeBooking_scannedProductsBody">
                <Col>
                    <ScannedProducts scannedProducts={form['products']}
                        UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                        RemoveFromForm={(barcode) => RemoveFromForm(barcode)}
                    />
                </Col>
            </Row>

            <Row className="negativeBooking_submitBlock mt-4">
                <Col>
                    <button className="stocktaking_m_submit w-100 py-2 fw-bold"
                        onClick={() => SubmitForm()}
                    >
                        Producten afboeken
                    </button>
                </Col>
            </Row>
        </Container>
    )
}

export default NegativeBookingMobile;