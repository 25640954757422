import axios from 'axios';


function InsertOrder(order, callback) {
    if (order) {
        const endPoint = "order";

        axios({
            method: "post",
            url: endPoint,
            data: order,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(result) {
            callback(result['data']);
        }).catch(error => {
            /* To be replaced by logger */
            console.warn(error);
        });
    }
}

export default InsertOrder;