/* Import Dependencies */
import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';

/* Import Components */
import OrderProducts from './orderContent/OrderProducts';


const OrderContent = (props) => {
    const { order, initialFormValues, PatchOrder, ReturnToOverview } = props;

    /* Form submitting */
    const formRef = useRef()

    const HandleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col onClick={() => ReturnToOverview()}>
                        <p className="order_m_return"> {`< Terug naar overzicht`} </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="order_m_contentTitle">
                        <p className="m-0"> {order['order_name']} </p>
                        <span className="order_m_contentStatus">
                            {order['order_state'][0].toUpperCase() + order['order_state'].slice(1, order['order_state'].length)}
                        </span>
                    </Col>
                    <Col className="col-auto">
                        <button type="button" className="order_m_contentSectionSave px-3 py-1"
                            onClick={() => HandleSubmit()}
                        >
                            Opslaan
                        </button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Row>
                            <Col>
                                Producten
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            {initialFormValues &&
                                <Formik
                                    initialValues={initialFormValues}
                                    enableReinitialize
                                    innerRef={formRef}
                                    onSubmit={async (form) => {
                                        await new Promise((resolve) => setTimeout(resolve, 100));

                                        PatchOrder(form);
                                    }}
                                >
                                    <Form>
                                        <Col className="order_m_contentSection">
                                            <OrderProducts orderProducts={order['order_products']} />
                                        </Col>
                                    </Form>
                                </Formik>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default OrderContent;