/* Import Dependencies */
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

/* Import Styles */
import './kaprun.css';

/* Import Webroot */
import KogelkrijgersLogo from 'webroot/img/kaprun.jpg';


const Kaprun = () => {
    const [countdown, setCountdown] = useState({});

    const end = new Date('03/04/2023 08:00 PM');

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    let timer;

    function showRemaining() {
        const now = new Date();
        const distance = end - now;
        if (distance < 0) {

            clearInterval(timer);
            document.getElementById('countdown').innerHTML = 'EXPIRED!';

            return;
        }
        const days = Math.floor(distance / _day);
        const hours = Math.floor((distance % _day) / _hour);
        const minutes = Math.floor((distance % _hour) / _minute);
        const seconds = Math.floor((distance % _minute) / _second);

        let dayString = 'dagen';
        let hourString = 'uren';
        let minuteString = 'minuten';
        let secondString = 'seconden';

        if (days === 1) {
            dayString = 'dag';
        }
        if (hours === 1) {
            hourString = 'uur';
        }
        if (minutes === 1) {
            minuteString = 'minuut';
        }
        if (seconds === 1) {
            secondString = 'seconde';
        }

        const copyCountdown = { ...countdown };

        copyCountdown['days'] = `${days} ${dayString}`;
        copyCountdown['hours'] = `${hours} ${hourString}`;
        copyCountdown['minutes'] = `${minutes} ${minuteString}`;
        copyCountdown['seconds'] = `${seconds} ${secondString}`;

        setCountdown(copyCountdown);
    }

    timer = setInterval(showRemaining, 1000);

    /* Screen width based functionality */
    const [mobile, setMobile] = useState(false);

    const updateWindowDimensions = () => {
        if (window.innerWidth > 500) {
            setMobile(false);
        } else {
            setMobile(true);
        }
    };

    useEffect(() => {
        updateWindowDimensions();

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    if (Object.keys(countdown).length > 0) {
        return (
            <Container fluid className="h-100 kaprun_bg">
                <Row className="d-flex justify-content-center pt-5">
                    <Col className="col-md-auto">
                        <img src={KogelkrijgersLogo} alt="Kögelkrijgers logo"
                            className="kaprun_logo"
                        />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }} className="kaprun_content py-5">
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h2 className="text-white">
                                    Nog...
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="countdown">
                                {!mobile ?
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            {`${countdown['days']}, ${countdown['hours']}, ${countdown['minutes']} en ${countdown['seconds']}`}
                                        </Col>
                                    </Row>
                                    : <>
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                {`${countdown['days']},`}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                {`${countdown['hours']},`}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                {`${countdown['minutes']} en`}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                {`${countdown['seconds']}`}
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Kaprun;