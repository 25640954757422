import {Row, Col} from 'react-bootstrap';
import './header.css'


const Header = (props) => {
    const headerTitle = props.info['title'];
    const headerSubTitle = props.info['subTitle'];

    return (
        <Row className='header'>
            <Col md={{span: 3}} className='headerTitle textOverflow'>
                {headerTitle}
            </Col>
            <Col md={{span: 9}} className='headerSubTitle textOverflow'>
                {headerSubTitle}
            </Col>
        </Row>
    )
}

export default Header