import axios from 'axios';


function GetProduct(productBarcode, callback) {
    if (productBarcode) {
        const endPoint = "product";
        const params = {
            product_barcode: productBarcode
        }

        axios({
            method: "get",
            url: endPoint,
            params: params,
            responseType: 'json'
        }).then(function(result) {
            callback(result['data']);
        }).catch(error => {
            /* To be replaced by logger */
            console.warn(error);
        });
    }
}

export default GetProduct;