/* Import Dependencies */
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'


const MainMenu = (props) => {
    const { scannedBarcode, SetCamera, ProcessBarcode } = props

    /* Hooks */
    const auth0 = useAuth0();
    const params = useParams();

    /* Functionality for active Menu items */
    const [activeMenuItem, setActiveMenuItem] = useState();

    function HighLightMenuItem(menuItem) {
        setActiveMenuItem(menuItem);

        CheckActive(menuItem);
    }

    function CheckActive(menuItem) {
        if (activeMenuItem === menuItem) {
            return 'mainMenuItem active';
        } else {
            return 'mainMenuItem';
        }
    }

    /* Check if menu item is opened */
    useEffect(() => {
        if (params['*']) {
            HighLightMenuItem(params['*']);
        }
    });

    /* Handling barcode input */
    const [barcode, setBarcode] = useState({});

    function SetBarcode(input) {
        const barcodeValue = input.target.value;

        setBarcode(barcodeValue);
    }

    function HandleKeyPress(event) {
        if (event.key === 'Enter') {
            SubmitBarcode();
        }
    }

    function SubmitBarcode() {
        if (barcode) {
            ProcessBarcode(barcode);
        }
    }

    return (
        <Row className="h-100">
            <Col className='mainMenu h-100'>
                <Link to="/scan">
                    <Row>
                        <Col md={{ span: 12 }} className={CheckActive('scan')} onClick={() => HighLightMenuItem('scan')}>
                            Scannen
                        </Col>
                    </Row>
                </Link>
                <Link to="/products">
                    <Row>
                        <Col md={{ span: 12 }} className={CheckActive('products')} onClick={() => HighLightMenuItem('products')}>
                            Producten
                        </Col>
                    </Row>
                </Link>
                <Link to='/stocktaking'>
                    <Row>
                        <Col md={{ span: 12 }} className={CheckActive('stocktaking')} onClick={() => HighLightMenuItem('stocktaking')}>
                            Inventarisatie
                        </Col>
                    </Row>
                </Link>
                <Link to='/order'>
                    <Row>
                        <Col md={{ span: 12 }} className={CheckActive('order')} onClick={() => HighLightMenuItem('order')}>
                            Bestellen
                        </Col>
                    </Row>
                </Link>
                <Row>
                    <Col md={{ span: 12 }} className={CheckActive('')}
                        onClick={() => auth0.logout()}
                    >
                        Log-out
                    </Col>
                </Row>

                { /* Barcode field */}
                <Row className='mainMenuBarcodeItem'>
                    <Col md={{ span: 12 }}>
                        <Row>
                            <Col>
                                <button type="button" onClick={() => SetCamera()}> Camera </button>
                            </Col>
                        </Row>
                        <Row>
                            <input
                                type='text'
                                className='col-md-10 barcodeScanField'
                                autoComplete='off'
                                defaultValue={scannedBarcode}
                                onChange={(input) => SetBarcode(input)}
                                onKeyPress={(event) => HandleKeyPress(event)}
                            />

                            <button
                                type='button'
                                className='col-md-2 barcodeSubmissionButton'
                                onClick={() => SubmitBarcode()}
                            >
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default MainMenu;