import { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';

/* Import Components */
import OrderProducts from './orderContent/OrderProducts';
import OrderDetails from './orderContent/OrderDetails';
import OrderFinish from './orderContent/OrderFinish';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';


const OrderContent = (props) => {
    const { order, initialFormValues, PatchOrder, FinishOrder } = props;

    /* Form submitting */
    const formRef = useRef()

    const HandleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    /* Handling the tabs */
    const [tabs, setTabs] = useState({
        products: 'active',
        details: '',
        finish: ''
    });

    function ToggleTab(tab) {
        const copyTabs = { ...tabs };

        Object.keys(copyTabs).forEach((copyTab) => {
            if (copyTab == tab) {
                copyTabs[copyTab] = 'active';
            } else {
                copyTabs[copyTab] = '';
            }
        });

        setTabs(copyTabs);
    }

    return (
        <Row>
            <Col className="order_contentBlock bg-white">
                {/* Top bar */}
                <Row className="order_contentTopBar">
                    {/* State */}
                    <Col className="order_contentState col-md-auto text-white h-100 px-4 d-flex align-items-center">
                        {order['order_state'] ?
                            order['order_state'][0].toUpperCase() + order['order_state'].slice(1, order['order_state'].length)
                            : 'Status'
                        }
                    </Col>
                    {/* Navigatin tabs */}
                    <Col className={`order_contentTab ${tabs['products']} col-md-auto h-100 px-4 d-flex align-items-center`}
                        onClick={() => ToggleTab('products')}
                    >
                        Producten
                    </Col>
                    <Col className={`order_contentTab ${tabs['details']} col-md-auto h-100 px-4 d-flex align-items-center`}
                        onClick={() => ToggleTab('details')}
                    >
                        Details en acties
                    </Col>
                    <Col className={`order_contentTab ${tabs['finish']} col-md-auto h-100 px-4 d-flex align-items-center`}
                        onClick={() => ToggleTab('finish')}
                    >
                        Afronden
                    </Col>
                    {/* Action buttons */}
                    <Col className="d-flex justify-content-end align-items-center">
                        <Row>
                            <Col>
                                <button type="submit" className="order_contentButton"
                                    onClick={() => HandleSubmit()}
                                >
                                    <FontAwesomeIcon icon={faSave}
                                        className="order_contentIcon text-white"
                                    />
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Content of tabs */}
                {Object.keys(order).length > 0 &&
                    <Row>
                        {initialFormValues &&
                            <Formik
                                initialValues={initialFormValues}
                                enableReinitialize
                                innerRef={formRef}
                                onSubmit={async (form) => {
                                    await new Promise((resolve) => setTimeout(resolve, 100));

                                    PatchOrder(form);
                                }}
                            >
                                <Form>
                                    <Col className={`order_contentSection ${tabs['products']}`}>
                                        <OrderProducts orderProducts={order['order_products']} />
                                    </Col>
                                    <Col className={`order_contentSection ${tabs['details']}`}>
                                        <OrderDetails order={order} />
                                    </Col>
                                </Form>
                            </Formik>
                        }

                        {initialFormValues &&
                            <Formik
                                initialValues={initialFormValues}
                                enableReinitialize
                                onSubmit={async (form) => {
                                    await new Promise((resolve) => setTimeout(resolve, 100));

                                    FinishOrder(form);
                                }}
                            >
                                <Form>
                                    <Col className={`order_contentSection ${tabs['finish']}`}>
                                        {Object.keys(order['order_products']['products']).length > 0 &&
                                            <OrderFinish order={order} />
                                        }
                                    </Col>
                                </Form>
                            </Formik>
                        }
                    </Row>
                }
            </Col>
        </Row>
    );
}

export default OrderContent;