import DataTable from 'react-data-table-component';
import { Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';


const OrderFinish = (props) => {
    const { order } = props;

    const tableColumns = [
        {
            name: 'Product',
            selector: row => row.product_name
        }, {
            name: 'Besteld',
            selector: row => row.product_ordered
        }, {
            name: 'Geleverd',
            selector: row => row.product_delivered
        }
    ];

    const tableData = [];

    Object.keys(order['order_products']['products']).forEach((barcode, i) => {
        const orderProduct = order['order_products']['products'][barcode];

        tableData.push({
            id: i,
            product_name: orderProduct['product_name'],
            product_ordered: orderProduct['amount'],
            product_delivered: <Field name={`amount.${i}`} type="number" className="w-100" />
        });
    });

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <FieldArray name="amount">
                            <DataTable
                                columns={tableColumns}
                                data={tableData}
                            />
                        </FieldArray>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <button type="submit">
                                    Bevestigen
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}

export default OrderFinish;