import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col } from 'react-bootstrap';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';


const AddOrderList = (props) => {
    const { suppliers, AddOrder } = props;

    const [addOrderListToggle, setAddOrderListToggle] = useState(false);

    function ToggleAddOrderList() {
        let toggle;

        if (addOrderListToggle) {
            toggle = false;
        } else {
            toggle = 'active';
        }

        setAddOrderListToggle(toggle);
    }

    return (
        <Row className="order_orderListsAddRow position-relative">
            <Col className={`order_orderListsAddBlock ${addOrderListToggle} position-absolute text-white`}>
                <Row>
                    <Col className="order_orderListsAddButton d-flex align-items-center"
                        onClick={() => { if (!addOrderListToggle) { ToggleAddOrderList() } }}
                    >
                        Nieuwe bestellijst
                    </Col>
                    {addOrderListToggle &&
                        <Col className="col-md-auto d-flex align-items-center">
                            <FontAwesomeIcon icon={faX}
                                className="order_orderListAddClose align-right"
                                onClick={(() => ToggleAddOrderList())}
                            />
                        </Col>
                    }
                </Row>

                <Formik
                    initialValues={{
                        order_name: "",
                        supplier_id: "",
                        auto_fill: false
                    }}
                    onSubmit={async (form) => {
                        await new Promise((resolve) => setTimeout(resolve, 100));

                        AddOrder(form);
                    }}
                >
                    <Form>
                        <Row>
                            {/* Field names */}
                            <Col className="order_orderListsAddLabels col-md-auto">
                                <Row className="mt-1">
                                    <Col>
                                        Naam:
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        Leverancier:
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        Autovul:
                                    </Col>
                                </Row>
                            </Col>
                            {/* Form fields */}
                            <Col>
                                <Row>
                                    <Col>
                                        <Field name="order_name" type="text"
                                            className="w-100"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <Field name="supplier_id" as="select"
                                            className="w-100"
                                        >
                                            <option value="" disabled>
                                                Kies leverancier
                                            </option>

                                            {Object.keys(suppliers).map((key, i) => {
                                                const supplier = suppliers[key];

                                                return (
                                                    <option key={i}
                                                        value={supplier['id']}
                                                    >
                                                        {supplier['supplier_name']}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Field name="auto_fill" type="checkbox" 
                                            className="checkbox"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <button type="submit" 
                                    className="orders_orderListsAddSubmit w-100 text-white py-1"
                                    onClick={() => ToggleAddOrderList()}
                                >
                                    Toevoegen
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </Col>
        </Row>
    );
}

export default AddOrderList;