import DataTable from 'react-data-table-component';
import { Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';


const OrderProducts = (props) => {
    const { orderProducts } = props;

    const tableColumns = [
        {
            name: 'Product',
            selector: row => row.product_name
        }, {
            name: 'Voorraad',
            selector: row => row.product_stock
        }, {
            name: 'Min',
            selector: row => row.product_min
        }, {
            name: 'Max',
            selector: row => row.product_max
        }, {
            name: 'Aantal',
            selector: row => row.product_amount
        }
    ];

    const tableData = [];

    if (orderProducts) {
        Object.keys(orderProducts['products']).forEach((barcode, i) => {
            const orderProduct = orderProducts['products'][barcode];
            
            tableData.push({
                id: i,
                product_name: orderProduct['product_name'],
                product_stock: orderProduct['warehouseproduct_stock'],
                product_min: orderProduct['warehouseproduct_minimum_stock'],
                product_max: orderProduct['warehouseproduct_maximum_stock'],
                product_amount: <Field name={`amount.${i}`} type="number" className="w-100" />
            });
        });
    }

    return (
        <Row>
            <Col>
                <FieldArray name="amount">
                    <DataTable
                        columns={tableColumns}
                        data={tableData}
                    />
                </FieldArray>
            </Col>
        </Row>
    );
}

export default OrderProducts;