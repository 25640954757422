/* Import Dependencies */
import { Row, Col } from 'react-bootstrap';


const ProductWarehouses = (props) => {
    const { warehousesProducts } = props;

    if (warehousesProducts.length > 0) {
        return (
            <Row>
                <Col className="scan_m_productWarehouses py-2">
                    <Row>
                        <Col className='scan_m_productWarehousesTitle fw-bold'>
                            Magazijnvoorraad
                        </Col>
                    </Row>

                    {warehousesProducts.map((warehouseProduct, i) => {
                        return (
                            <Row key={i} className="pt-2">
                                <Col>
                                    <div className="scan_m_productWarehousesBlock px-2">
                                        <Row>
                                            <Col className="scan_m_productWarehousesBlockTitle">
                                                {warehouseProduct['warehouse']['warehouse_name']}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="scan_m_productWarehousesBlockContent">
                                                {`Voorraad: ${warehouseProduct['warehouseproduct_stock']}`}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </Col>
            </Row>
        );
    } else {
        return (
            <Row>
                <Col>
                    Nog geen voorraad geregistreerd
                </Col>
            </Row>
        );
    }
}

export default ProductWarehouses;