/* Import Dependencies */
import { Formik, Form, Field } from 'formik';
import { Modal, Row, Col, ModalHeader, ModalTitle } from 'react-bootstrap';

/* Import Icons */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';


const AddOrderList = (props) => {
    const { suppliers, modalToggle, AddOrder, SetModalToggle } = props;

    return (
        <Modal show={modalToggle}>
            <Modal.Header>
                <Modal.Title>
                    Bestellijst toevoegen
                </Modal.Title>

                <button type="button" className="order_m_orderListModalClose px-2 py-1"
                    onClick={() => SetModalToggle()}
                >
                    <FontAwesomeIcon icon={faX} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        order_name: "",
                        supplier_id: "",
                        auto_fill: false
                    }}
                    onSubmit={async (form) => {
                        await new Promise((resolve) => setTimeout(resolve, 100));

                        AddOrder(form);
                    }}
                >
                    <Form>
                        <Row>
                            <Col className="order_orderListsAddLabels">
                                <Row className="mt-1">
                                    <Col xs={{ span: 12 }}>
                                        Naam:
                                    </Col>
                                    <Col xs={{ span: 12 }} className="mt-1">
                                        <Field name="order_name" type="text"
                                            className="w-100"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col xs={{ span: 12 }}>
                                        Leverancier:
                                    </Col>
                                    <Col xs={{span: 12}} className="mt-1">
                                        <Field name="supplier_id" as="select"
                                            className="w-100"
                                        >
                                            <option value="" disabled>
                                                Kies leverancier
                                            </option>

                                            {Object.keys(suppliers).map((key, i) => {
                                                const supplier = suppliers[key];

                                                return (
                                                    <option key={i}
                                                        value={supplier['id']}
                                                    >
                                                        {supplier['supplier_name']}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="col-auto">
                                        Autovul:
                                    </Col>
                                    <Col>
                                        <Field name="auto_fill" type="checkbox"
                                            className="checkbox"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <button type="submit"
                                    className="orders_orderListsAddSubmit w-100 text-white py-1"
                                    onClick={() => SetModalToggle(false)}
                                >
                                    Toevoegen
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

export default AddOrderList;