/* Import Dependencies */
import { Container, Row, Col } from 'react-bootstrap';

/* Import Dependencies */
import ScannedProducts from './mobile/ScannedProducts';
import StocktakingOptions from './mobile/StocktakingOptions';


const StocktakingMobile = (props) => {
    const {
        form, bookingReasons, fromWarehouse,
        UpdateForm, SubmitForm, ToggleFromWarehouse
    } = props;

    return (
        <Container fluid className="contentWithHeaderMobile pt-4 pb-4">
            <Row>
                <Col>
                    <StocktakingOptions form={form}
                        fromWarehouse={fromWarehouse}

                        UpdateForm={(field, value) => UpdateForm(field, value)}
                        ToggleFromWarehouse={(toggle) => ToggleFromWarehouse(toggle)}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <ScannedProducts scannedProducts={form['products']}
                        bookingReasons={bookingReasons}
                        fromWarehouse={fromWarehouse}

                        UpdateForm={(field, value, barcode) => UpdateForm(field, value, barcode)}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col>
                    <button className="stocktaking_m_submit w-100 py-2 fw-bold"
                        onClick={() => SubmitForm()}
                    >
                        Uitvoeren
                    </button>
                </Col>
            </Row>
        </Container>
    )
}

export default StocktakingMobile;