import axios from 'axios';


function GetOrder(orderId, callback) {
    if (orderId) {
        const endPoint = "order";
        const params = {
            order_id: orderId
        }

        axios({
            method: "get",
            url: endPoint,
            params: params,
            responseType: 'json'
        }).then(function(result) {
            callback(result['data']);
        }).catch(error => {
            /* To be replaced by logger */
            console.warn(error);
        });
    }
}

export default GetOrder;