import { Formik, Form, Field } from 'formik';
import { Row, Col } from 'react-bootstrap';


const OrderDetails = () => {
    return (
        <Row>
            <Col md={{ span: 6 }}>
                <Row>
                    <Col className="order_orderDetailsBlock">
                        <Row>
                            <Col className="fw-bold">
                                Mailen
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default OrderDetails;