import axios from 'axios';


function CompleteOrder(orderId, orderProducts, callback) {
    if (orderId && orderProducts) {
        const endPoint = "orderComplete";

        axios({
            method: "post",
            url: endPoint,
            data: {
                order_id: orderId,
                order_products: orderProducts
            },
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function(result) {
            callback(result['data']['order_id']);
        }).catch(error => {
            /* To be replaced by logger */
            console.warn(error);
        });
    }
}

export default CompleteOrder;